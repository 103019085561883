<template>
    <div>
      <section>
        <div id="filter">
          <base-button class="m-3" @click="toggleFilter">Filter</base-button>
        </div>
        <shop-filter
          v-if="showFilter"
          @searchByLocation="searchByLocation"
          @searchByPrice="searchByPrice"
        ></shop-filter>
      </section>
      <section>
        <div class="container">
          <div class="row justify-content-around">
            <div v-if="isLoading">
              <base-spinner></base-spinner>
            </div>
            <admin-shop-item
              class="col-sm-5 col-md-4 mt-3 m-1"
              v-for="shop in filteredShops"
              :key="shop.id"
              :shop="shop"
            ></admin-shop-item>
          </div>
        </div>
      </section>
    </div>
  </template>
    
    <script>
  import AdminShopItem from "../../components/admin/AdminShopItem.vue";
  import ShopFilter from "../../components/shops/ShopFilter.vue";
  export default {
    components: {
      AdminShopItem,
      ShopFilter,
    },
    data() {
      return {
        showFilter: false,
        isLoading: false,
        location: "All",
        price: 100000000,
        locations: [
          "Bugoloobi",
          "Bukoto",
          "Busega",
          "Butabika",
          "Bwaise",
          "Ggaba",
          "Kabalagala",
          "Kaleerwa",
          "Kampala Hill",
          "Kasanga",
          "Kibuli",
          "Kisenyi",
          "Lungujja",
          "Lugogo",
          "Makerere",
          "Makerere Kikoni",
          "Makindye",
          "Mengo",
          "Munyonyo",
          "Naakulabye",
          "Nakasero",
          "Nakawa",
          "Namirembe Hill",
          "Nateete",
          "Nsambya",
          "Ntinda",
          "Wandegeya",
      ]
      };
    },
    computed: {
      // rentals() {
      //   return this.$store.getters["rentals/properties"];
      // },
      filteredShops() {
        const shops = this.$store.getters["shops/properties"];
        return shops.filter((shop) => {
          if (
            this.location === "All" &&
            shop.price <= this.price &&
            !this.isLoading
          ) {
            return true;
          }
  
          for (let i = 0; i < this.locations.length; i++) {
            if (
              !this.isLoading &&
              this.location === this.locations[i] &&
              shop.location === this.locations[i] &&
              shop.price <= this.price
            ) {
              return true;
            }
          }
  
          return false;
        });
      },
    },
    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter;
      },
      async loadShops() {
        this.isLoading = true;
        await this.$store.dispatch("shops/loadShops");
        this.isLoading = false;
      },
      searchByLocation(value) {
        // console.log(value.price)
        // this.location = value.location;
        // this.price = value.price
        this.location = value;
      },
      searchByPrice(value) {
        this.price = value;
      },
    },
    created() {
      this.loadShops();
      //   const shops = this.$store.getters["shops/properties"];
      //   console.log(shops)
    },
    // updated() {
    //   this.loadRentals();
    // }
  };
  </script>
    
  <style scoped>
  #filter {
    text-align: center;
  }
  </style>