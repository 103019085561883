<template>
  <div id="theWholeCard">
    <div
      :id="shop.id"
      class="carousel carousel-dark slide"
      data-bs-pause="false"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item imagePart"
          v-for="image in shop.imageUrls"
          :key="image"
          :class="image === shop.imageUrls[0] ? 'active' : ''"
        >
          <router-link :to="rentalDetail" class="imageContainer">
            <img
              :src="image"
              class="img-fluid rounded d-block w-100"
              :alt="shop.id"
            />
            <p style="color: white" class="top-right">
              {{ 1 + shop.imageUrls.indexOf(image) }} /
              {{ shop.imageUrls.length }}
            </p>
          </router-link>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        :data-bs-target="'#' + shop.id"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon bclour"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        :data-bs-target="'#' + shop.id"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon bclour"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="">
      <p class="card-title m-0">
        <span class="fst-itali">{{ shop.buildingName }}</span>
      </p>
      <p class="card-text m-0">
        <span class="fw-normal">{{ shop.location }}, Kampala</span>
      </p>
      <p class="card-text m-0">
        <span class="fw-normal">{{ shop.description }}</span>
      </p>
      <p>
        <strong>{{ shop.price }} UGX</strong> / month
      </p>

      <p>
        Contact us on
        <strong><a href="tel: +256789647271">+256 789647271</a></strong>
      </p>
      <div class="approved">
        <p>
          Approved: <span>{{ shop.approved }}</span>
        </p>
        <button class="btn btn-primary" @click="approveShop">
          {{ approveContent }}
        </button>
      </div>
      <div class="book">
        <p>
          booked: <span>{{ shop.booked }}</span>
        </p>
        <button class="btn btn-primary" @click="bookShop">
          {{ bookContent }}
        </button>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  props: ["shop"],
  computed: {
    contactLink() {
      return "/Offices&Shops/" + this.shop.id + "/contact";
    },
    rentalDetail() {
      return "/Offices&Shops/" + this.shop.id;
    },
    approveContent() {
      if(this.shop.approved) {
        return 'Disapprove Rental'
      } else {
        return 'Approve Rental'
      }
    },
    bookContent() {
      if(this.shop.booked) {
        return 'Unbook rental'
      } else {
        return 'Book rental'
      }
    }
  },
  methods: {
    async approveShop() {
        await this.$store.dispatch('admin/approveShop', {id: this.shop.id, approved: this.shop.approved})
        this.$router.replace("/offices&Shops");
    },
    async bookShop() {
        await this.$store.dispatch('admin/bookShop', {id: this.shop.id, booked: this.shop.booked})
        this.$router.replace('/offices&Shops')
    }
  },
};
</script>
    
    <style scoped>
#theWholeCard {
  max-height: 120vh;
  max-width: 25rem;
}
span {
  font-weight: bold;
}

div .imagePart {
  width: 100%;
  height: 20rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: rgb(65, 59, 59);
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 0.2rem;
}

.bclour {
  background-color: black;
  border-radius: 0.3rem;
}
</style>